<template>
  <section class="seeAlso">
    <div class="container">
      <h2 class="sectionTitle bef">
        {{ $t("seeAlsoTitle") }} <span>{{ $route.params.countryName }}</span>
      </h2>
      <div class="variants">
        <div class="item" v-for="(item, idx) in tabList" :key="idx">
          <router-link
            v-if="item.type === 'hotels'"
            :to="{
              name: 'ContryHotels',
              params: {
                countryName: $route.params.countryName,
                locale: currentLangForLink
              }
            }"
            >{{ item.tab_name || item.name }} <i class="ic-arrow"></i
          ></router-link>
          <router-link
            v-else-if="item.type === 'tours'"
            :to="{
              name: 'CountryTours',
              params: {
                countryName: $route.params.countryName,
                locale: currentLangForLink
              }
            }"
            >{{ item.tab_name || item.name }} <i class="ic-arrow"></i
          ></router-link>
          <router-link
            v-else-if="item.type === 'info'"
            :to="{
              name: 'CountryInfos',
              params: {
                countryName: $route.params.countryName,
                infoPageSlug: item.slug,
                locale: currentLangForLink
              }
            }"
            >{{ item.tab_name || item.name }} <i class="ic-arrow"></i
          ></router-link>
          <router-link
            v-else
            :to="{
              name: 'Contry',
              params: {
                countryName: $route.params.countryName,
                locale: currentLangForLink
              }
            }"
          >
            {{ item.tab_name || item.name }} <i class="ic-arrow"></i>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    tabList: {
      type: [Array, Object],
      require: true,
      default: () => []
    }
  }
};
</script>

<style lang="sass" scoped>
.sectionTitle span
  text-transform: capitalize
.seeAlso
    text-align: center
    @include sectionPadding

.variants
    margin-top: 70px
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 30px
    @media (max-width: 768px)
        grid-template-columns: repeat(2, 1fr)
        grid-gap: 22px
    @media (max-width: 576px)
        grid-template-columns: 1fr
    .item
        & > a
            width: 100%
            transition: .3s
            background-color: #28292F
            padding: 30px 32px
            display: flex
            justify-content: space-between
            text-align: left
            font-size: 17px
            line-height: 150%
            color: #FFFFFF
            i
                color: $c-btn
            &:hover
                background-color: #2A4651
            &.router-link-exact-active.router-link-active
                background-color: $c-btn
                color: #fff
</style>
