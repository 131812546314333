<template>
  <!-- <ul class="pagination">
    <li class="prev"><i class="ic-arrow"></i></li>
    <li v-for="i in 5" :key="i">
      {{ i }}
    </li>
    <li class="next"><i class="ic-arrow"></i></li>
  </ul> -->
  <ul class="pagination">
    <template v-for="(item, index) in compdata">
      <a
        v-if="item.type == 'prev'"
        :key="index"
        href="#"
        class="arrow prev"
        :class="{ disabled: !item.s }"
        @click.prevent="onPrew(item.s)"
      >
        <i class="ic-arrow"></i>
      </a>
      <a
        v-if="item.type == 'page'"
        :key="index"
        href="#"
        :class="{ current: item.current }"
        @click.prevent="$emit('click', item.l)"
        >{{ item.l }}</a
      >
      <span v-if="item.type == 'dot'" :key="index" class="dots"
        ><div></div
      ></span>
      <a
        v-if="item.type == 'next'"
        :key="index"
        href="#"
        class="arrow next"
        :class="{ disabled: !item.s }"
        @click.prevent="onNext(item.s)"
      >
        <i class="ic-arrow"></i>
      </a>
    </template>
  </ul>
</template>

<script>
export default {
  name: "pagination",
  props: ["compdata"],
  methods: {
    onPrew(s) {
      if (s) this.$emit("click", "prev");
    },
    onNext(s) {
      if (s) this.$emit("click", "next");
    }
  }
};
</script>

<style lang="sass" scoped>
.pagination
    display: flex
    justify-content: center
    width: 100%
    // margin-bottom: 65px
    padding: 60px 0
    a, span
        width: 20px
        height: 20px
        border: 1px solid #777
        cursor: pointer
        display: flex
        justify-content: center
        align-items: center
        font-size: 12px
        line-height: 100%
        text-align: center
        color: #777777
        font-weight: 500
        margin: 0px 5px
        transition: .3s
        &.current
            background-color: $c-btn
            color: #fff
            border-color: $c-btn
            &:hover
              color: $c-white
        &.disabled
            display: none
        &:hover
            color: $c-btn
            border-color: $c-btn
    .prev, .next
        border: none
    .prev
        transform: rotate(180deg)
.gc5
    grid-column: 1 / 5
</style>
