var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Flickity',{staticClass:"tabs",attrs:{"options":_vm.flickityOptions}},[_vm._l((_vm.tabList),function(item,idx){return [(item.type === 'hotels')?_c('router-link',{key:idx,attrs:{"to":{
        name: 'ContryHotels',
        params: {
          countryName: _vm.$route.params.countryName,
          locale: _vm.currentLangForLink
        }
      }}},[_vm._v(_vm._s(item.tab_name || item.name))]):(item.type === 'tours')?_c('router-link',{key:idx,attrs:{"to":{
        name: 'CountryTours',
        params: {
          countryName: _vm.$route.params.countryName,
          locale: _vm.currentLangForLink
        }
      }}},[_vm._v(_vm._s(item.tab_name || item.name))]):(item.type === 'info')?_c('router-link',{key:idx,attrs:{"to":{
        name: 'CountryInfos',
        params: {
          countryName: _vm.$route.params.countryName,
          infoPageSlug: item.slug,
          locale: _vm.currentLangForLink
        }
      }}},[_vm._v(_vm._s(item.tab_name || item.name))]):(item.type == 'newsCategory')?[(_vm.$route.params.slug != item.slug)?_c('router-link',{key:idx,attrs:{"to":{
          name: 'BlogCategory',
          params: {
            slug: item.slug,
            locale: _vm.currentLangForLink
          }
        }}},[_vm._v(" "+_vm._s(item.tab_name || item.name)+" ")]):_c('router-link',{key:idx,staticClass:"active",attrs:{"to":{
          name: 'Blog',
          params: {
            locale: _vm.currentLangForLink
          }
        }}},[_vm._v(" "+_vm._s(item.tab_name || item.name)+" ")])]:_c('router-link',{key:idx,attrs:{"to":{
        name: 'Contry',
        params: {
          countryName: _vm.$route.params.countryName,
          locale: _vm.currentLangForLink
        }
      }}},[_vm._v(" "+_vm._s(item.tab_name || item.name)+" ")])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }